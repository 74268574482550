import { ApiCall } from './utils'

//image
const getById = (id) => ApiCall('get', `/image/${id}`)

const search = (params) => ApiCall('post', `/image/search`, params)
const searchByElastic = (from, size, params) => ApiCall('post', `/search/image/${from}/${size}`, params)

const update = (params) => ApiCall('post', `/image`, params)

const deleteById = (id) => ApiCall('delete', `/image/${id}`)

const process = (params) => ApiCall('post', `/image/${params.id}/process`, params)

const updateLive = (id, live) => ApiCall('put', `/image/${id}/live/${live}`)

//Credit
const getCredit = (id) => ApiCall('get', `/image/${id}/credit`)

const updateCredit = (id, params) => ApiCall('post', `/image/${id}/credit`, params)

const deleteCredit = (id) => ApiCall('delete', `/image/credit/${id}`)

//tag
const getTag = (id) => ApiCall('get', `/image/${id}/tag`)

const updateTag = (id, params) => ApiCall('post', `/image/${id}/tag`, params)

const deleteTag = (id) => ApiCall('delete', `/image/tag/${id}`)

//entity_tag
const getEntityTag = (id) => ApiCall('get', `/image/${id}/entity_tag`)
const updateEntityTag = (id, params) => ApiCall('post', `/image/${id}/entity_tag`, params)
const deleteEntityTag = (id) => ApiCall('delete', `/image/entity_tag/${id}`)

const getRelatedImage = (id) => ApiCall('get', `/image/${id}/related_image`)
const updateRelatedImage = (id, params) => ApiCall('post', `/image/${id}/related_image`, params)
const deleteRelatedImage = (id, params) => ApiCall('delete', `/image/related_image/${id}`, params)

const getRelatedItem = (id) => ApiCall('get', `/image/${id}/related_item`)

// bulk_edit
const updateImagesBulk = (params) => ApiCall('post', `/image/bulk`, params)
const udpateImagesCreditsBulk = (params) => ApiCall('post', `/image/bulk/credit`, params)

export default {
    getById,
    search,
    searchByElastic,
    update,
    deleteById,
    process,

    updateLive,

    getCredit,
    updateCredit,
    deleteCredit,

    getTag,
    updateTag,
    deleteTag,

    getEntityTag,
    updateEntityTag,
    deleteEntityTag,

    getRelatedImage,
    updateRelatedImage,
    deleteRelatedImage,

    getRelatedItem,

    updateImagesBulk,
    udpateImagesCreditsBulk
}